import React from 'react';
import './styles/Home.css';
import mockup from './mockup.png'
import mockup2 from './mockup3.png'
import Main from './Main';


function Home() {
  return (
    <div className="home-container">
      <header className="hero-section">
        <div className='left'>
          <div className='hero-text'>
          <h1>The First Event-based <br/> <span className="gradient-text">Dating App</span></h1>
          <p>Available soon in Apple and Google Play stores</p>
          </div>
          <div className='btn-container'>
            <img src="https://assets.codepen.io/6060109/IG-app-store-button.png" alt="apple store button" class="btn"/>
            <img src="https://assets.codepen.io/6060109/IG-Google-Play-Button.png" alt="google play button" class="btn"/>
          </div>
        </div>
        <div className="right">
          <img src={mockup} className='mockup-1'/>
          <img src={mockup2} className='mockup-2'/>
        </div>
      </header>
      <main>
        {/* <Main/> */}
      </main>
    </div>
  );
}

export default Home;
