import React, { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import Navbar from "./Navbar";
import Home from "./Home";
import Events from "./Events";
import Download from "./Download";
import About from "./About";
import Contact from "./Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Router>
      <div>
        {isLoading ? (
          <LinearProgress color="secondary" />
        ) : (
          <div>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Events />} />
              <Route path="/download" element={<Download />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
