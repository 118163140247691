import React from 'react';
import "./styles/About.css"

function About() {
  return (
    <div className='about-container'>
      <h1>About Us</h1>
      <p>This is the about page!</p>
    </div>
  );
}

export default About;
