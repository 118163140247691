import React from "react";
import { Link } from "react-router-dom";
import "./styles/Navbar.css";
import logo from "./logo.png";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="Bloom" />
        <Link to="/">
          <span>BLOOM</span>
        </Link>
      </div>
      <div className="navbar-center">
        {/* <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/download">Download</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
        </ul> */}
      </div>
      <div className="navbar-right">
        <span>Have any questions?</span>
        <a href="mailto:info@thebloom.app"><span>info@thebloom.app</span></a>
      </div>
    </nav>
  );
}

export default Navbar;
